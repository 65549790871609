interface IPlatformInfo {
  osName: string;
  osVersion: string;
  browserName: string;
  browserVersion: string;
  platformType: string;
  pwa: boolean;
  mobile: boolean;
  pwaLinkSupported: boolean;
  pushSupportingIosVersion: boolean;
  oldIosVersion: boolean;
  androidAppCompatible: boolean;
}

export const usePlatformStore = defineStore("platform", () => {
  const platformInfo = reactive<IPlatformInfo>({
    osName: "",
    osVersion: "",
    browserName: "",
    browserVersion: "",
    platformType: "",
    pwa: false,
    pwaLinkSupported: false,
    mobile: false,
    pushSupportingIosVersion: false,
    oldIosVersion: false,
    androidAppCompatible: false,
  });

  const iosPwaWithPushSupport = computed(
    () =>
      platformInfo.pwa && platformInfo.osName === "ios" && platformInfo.pushSupportingIosVersion,
  );

  const showAndroidAppBanner = computed(() => platformInfo.androidAppCompatible);

  const isIos = computed(() => platformInfo.osName === "ios");
  const isPwa = computed(() => platformInfo.pwa);

  const showIosAppBanner = computed(() => isIos.value && !platformInfo.oldIosVersion);

  const setValues = (info: IPlatformInfo) => {
    Object.assign(platformInfo, info);
  };

  return {
    platformInfo,
    isIos,
    isPwa,
    iosPwaWithPushSupport,
    showAndroidAppBanner,
    showIosAppBanner,
    setValues,
  };
});
